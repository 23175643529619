import React, { useEffect, useState } from "react";
import cn from "classnames";
import Imgix from "react-imgix";
import { useSelector } from "react-redux";
import { navigate } from "gatsby-link";
import styles from "./Onboarding.module.scss";
import RoundedButton from "../Buttons/RoundedButton";
import Timeline from "../Timeline/Timeline";
import { useAppDispatch } from "../../store";
import {
  selectInstagramBasicAccounts,
  selectInstagramBasicUrl,
} from "../../store/user/selectors";
import {
  getInstagramBasicAuthUrl,
  InstagramBasicAccounts,
  InstagramBasicInterface,
} from "../../store/user/actions";

import { sendToast } from "../../utils/helpers";
import { setLocation } from "../../store/user/reducer";

const StandingImage =
  "https://imgix.cosmicjs.com/eda4e4b0-3f46-11ec-9828-0f2f00f103e0-bidfootstanding-1.png";

const Authorize = ({ path = null }) => {
  const dispatch = useAppDispatch();
  const instagramBasicUrl = useSelector((state) =>
    selectInstagramBasicUrl(state)
  ) as InstagramBasicInterface | null;
  const instagramAccounts = useSelector((state) =>
    selectInstagramBasicAccounts(state)
  ) as InstagramBasicAccounts[] | null;
  const [checkingAccounts, setCheckingAccounts] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  useEffect(() => {
    const currentStateObject = {
      type: "AUTHORIZE",
      navigationPath: path,
    };
    const url = `auth-redirect/`;
    const stateData = `${encodeURIComponent(
      JSON.stringify(currentStateObject)
    )}`;
    const object = {
      url,
      state: stateData,
    };
    // Handle stuff.
    dispatch(getInstagramBasicAuthUrl(object));
  }, []);

  const onAuthorize = () => {
    if (localLoading) {
      return;
    }
    setLocalLoading(true);
    if (instagramBasicUrl) {
      dispatch(setLocation(path));
      const { url } = instagramBasicUrl;
      window.location.href = url;
    }
    setLocalLoading(false);
  };

  useEffect(() => {
    if (instagramAccounts && instagramAccounts.length > 0) {
      // TODO: handle the scenario of multiple different accounts
      navigate("/dashboard/getting-started/connected/", {
        state: { navigationPath: path },
      });
    }
    if (
      checkingAccounts &&
      instagramAccounts &&
      instagramAccounts.length === 0
    ) {
      setCheckingAccounts(false);
      sendToast(
        "There was an error connecting your Instagram Account or a cancelation in the authorization. Please try authorizing again.",
        { type: "error" }
      );
    }
  }, [instagramAccounts, checkingAccounts]);

  return (
    <div
      className={cn([
        styles.howItWorksOnboardingContainer,
        styles.authorizeContainer,
        styles.defaultContainer,
      ])}
    >
      <Timeline selectedDot={0} />
      <div className={styles.congratsImageContainer}>
        <Imgix
          src={`${StandingImage}?auto=format`}
          width={279}
          height={262}
          htmlAttributes={{
            alt: `Authorize Bidddy`,
            src: `${StandingImage}?auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
        />
      </div>
      <h3>Let&apos;s Check...</h3>
      <h2>What kind of Instagram Account do I have?</h2>
      <p>
        The fastest way to check what kind of account you have is to{" "}
        <strong>
          authorize Bidddy to access your Profile Info on Instagram.
        </strong>
      </p>
      <RoundedButton
        onClick={() => onAuthorize()}
        disabled={instagramBasicUrl === null || localLoading}
        id="submit"
      >
        Authorize Now
      </RoundedButton>
    </div>
  );
};

export default Authorize;
