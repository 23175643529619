import React from "react";
import cn from "classnames";
import styles from "./Timeline.module.scss";

const Timeline = ({ selectedDot = null }) => (
  <div className={styles.timelineContainer}>
    <div
      className={cn([
        styles.dotsContainer,
        {
          [styles.firstHalf]: selectedDot === 1,
          [styles.secondHalf]: selectedDot > 1,
        },
      ])}
    >
      <div
        className={cn([
          styles.dotBlock,
          { [styles.selectedDot]: selectedDot !== null && selectedDot >= 0 },
        ])}
      >
        <div className={styles.dot} />
        <p>
          Account
          <br />
          Setup
        </p>
      </div>
      <div
        className={cn([
          styles.dotBlock,
          { [styles.selectedDot]: selectedDot >= 1 },
        ])}
      >
        <div className={styles.dot} />
        <p>
          Connect
          <br /> Bidddy
        </p>
      </div>
      <div
        className={cn([
          styles.dotBlock,
          { [styles.selectedDot]: selectedDot >= 2 },
        ])}
      >
        <div className={styles.dot} />
        <p>
          Confirm <br />
          Account
        </p>
      </div>
    </div>
  </div>
);

export default Timeline;
